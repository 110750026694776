import Grid from "@mui/material/Grid";
import MenuItem from '@mui/material/MenuItem';
import Paper from "@mui/material/Paper";
import Typography from '@mui/material/Typography';
import { SelectValidator, TextValidator } from 'react-material-ui-form-validator';
import { IClientDiscoveryFormTabProps } from "./ClientDiscoveryForm";



export default function ClientDiscoveryContent({ data, handleCheckChange, handleDropdownChange, handleTextChange }: IClientDiscoveryFormTabProps) {
    return (
        <Paper sx={{ padding: "8px" }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5">CONTENT - FOR MARKETING TO ASK</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">With the content element of your package, I'll let [ABBEY/JOE/ETC] ask a couple of questions for their purposes</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">CONTENT PROCESS</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        It would be great to learn a little more about your current marketing strategy and how we can tie into it.

                        To be clear on how the process works, I will create content for you monthly, sending you over the plan for the month ahead of time and arranging a call to discuss and make sure you are happy, nothing is posted without you approval so these calls are vital to ensure there are no breaks in activity on your Linkedin page.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Which LinkedIn do you wish to utilise:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        "To confirm, which page do you wish to grow and utilise the company or the personal page?"
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <SelectValidator
                        name="whichLinkedIn"
                        value={data.whichLinkedIn}
                        onChange={handleDropdownChange}
                        fullWidth
                        required
                        size="small"
                    >
                        <MenuItem key={0} value={"Business"}>Business</MenuItem>
                        <MenuItem key={1} value={"Personal"}>Personal</MenuItem>
                    </SelectValidator>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Admin Access:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        Only as this question, if they want to use the company page!

                        "To confirm, do you have admin access to your company Linkedin page?"

                        If they don't, I would recommend you ask them to get this ASAP or give them a quick tutorial on how they can get their CEO etc to do this.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <SelectValidator
                        name="adminAccess"
                        value={data.adminAccess}
                        onChange={handleDropdownChange}
                        fullWidth
                        required
                        size="small"
                    >
                        <MenuItem key={0} value={"Yes"}>Yes</MenuItem>
                        <MenuItem key={1} value={"No"}>No</MenuItem>
                    </SelectValidator>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">Tone of Voice/ Writing style</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        "The content's tone refers to the attitude you want each piece of content to convey (e.g., formal, informal, friendly, assertive, optimistic); while the writing style is related to the purpose of the content (e.g., inform, educate, persuade, describe)"
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="contentTone"
                        value={data.contentTone}
                        onChange={handleTextChange}
                        fullWidth
                        size="small"
                        multiline
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Topics/subject matters</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        "What topic(s) would you like the content to cover? So have any specific points you would like to be included? This can be interesting news in your industry or highlight exciting new services you offer and their benefits to your clients/customers."
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="topics"
                        value={data.topics}
                        onChange={handleTextChange}
                        fullWidth
                        size="small"
                        multiline
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">What facts and emotional reasons will make people buy/convert?</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        "What are the consumer pain points?"
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="factsAndReasons"
                        value={data.factsAndReasons}
                        onChange={handleTextChange}
                        fullWidth
                        size="small"
                        multiline
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">What are the key points that need to be communicated to the consumer?</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        "What will make buyers believe us? So have any specific points you would like to be included? This can be interesting news in your industry or highlight exciting new services you offer and their benefits to your clients/customers."
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="keyPoints"
                        value={data.keyPoints}
                        onChange={handleTextChange}
                        fullWidth
                        size="small"
                        multiline
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">What are the goals of the project? How will we measure success?</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        "What are your goal (s) for this project? Increase brand awareness, activity, following?"
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="projectGoals"
                        value={data.projectGoals}
                        onChange={handleTextChange}
                        fullWidth
                        size="small"
                        multiline
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Content Exclusions</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        "What is the campaign not going to cover?
                        What messages, emotions, or features do you want to avoid?"
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="contentExclusions"
                        value={data.contentExclusions}
                        onChange={handleTextChange}
                        fullWidth
                        size="small"
                        multiline
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">American English vs British English </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        "We have clients all over the world, who have different preferences regarding spelling. Some prefer British English (e.g., Organisation, Colour, Defence); while others prefer American English (e.g., Organization, Color, Defense."
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <SelectValidator
                        name="englishType"
                        value={data.englishType}
                        onChange={handleDropdownChange}
                        fullWidth
                        required
                        size="small"
                        validators={['required']}
                        errorMessages={['This field is required']}

                    >
                        <MenuItem key={0} value={"British"}>British</MenuItem>
                        <MenuItem key={1} value={"American"}>American</MenuItem>
                    </SelectValidator>
                </Grid>
                
                <Grid item xs={12}>
                    <Typography variant="h6">IF DOING CONTENT AND ADS - if there's a specific piece of content that is working well, we can use that as a lead magnet for ads or for outreach</Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}