import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from '@mui/material/Typography';
import { IClientDiscoveryFormTabProps } from "./ClientDiscoveryForm";

export default function ClientDiscoveryAds({ data, handleCheckChange }: IClientDiscoveryFormTabProps) {
    return (
        <Paper sx={{ padding: "8px" }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5">ADS - FOR TOM TO ASK</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">"Great! Thank you for this. I'll let Tom run through his process and ask any questions!</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Message Tone:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        "For your messaging then, just to help me get it right first time, is there any particular language or tone you would like me to use?'
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={data.accessCompanyPage} name="accessCompanyPage" onChange={handleCheckChange} />} label="I need access to your Company page/Ads Account at admin level" />
                        <FormControlLabel control={<Checkbox checked={data.firstMonth} name="firstMonth" onChange={handleCheckChange} />} label="The first month is focussed on engagement, completely TOF to retarget them at the MOF" />
                        <FormControlLabel control={<Checkbox checked={data.assetDesign} name="assetDesign" onChange={handleCheckChange} />} label="We will design all ad assets, copy and creatives - so please send over any brand imagery" />
                        <FormControlLabel control={<Checkbox checked={data.requiredEngagement} name="requiredEngagement" onChange={handleCheckChange} />} label="Once we hit the required engagement, we can switch to MOF, but our first month is mostly engagement" />
                    </FormGroup>
                </Grid>
            </Grid>
        </Paper>
    );
}