import * as React from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useLocation } from 'react-router';
import authService from '../api-authorization/AuthorizeService';
import Container from '@mui/material/Container';
import ClientDiscoveryFormViewModel from './ClientDiscoveryFormViewModel';
import ClientDiscovery1 from './ClientDiscovery1';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ClientDiscovery2 from './ClientDiscovery2';
import ClientDiscovery3 from './ClientDiscovery3';
import ClientDiscovery4 from './ClientDiscovery4';
import ReturnViewModel from '../ViewModels/ReturnViewModel';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import ClientDiscoveryContent from './ClientDiscoveryContent';
import ClientDiscoveryAds from './ClientDiscoveryAds';


interface ClientDiscoveryFormState {
    campaignId: number;
}

export interface IClientDiscoveryFormTabProps {
    data: ClientDiscoveryFormViewModel;
    handleCheckChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    handleDropdownChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleTextChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function ClientDiscoveryForm() {
    const location = useLocation<ClientDiscoveryFormState>();
    const { campaignId } = location.state || {
        campaignId: 0
    };

    const [clientDiscoveryForm, setClientDiscoveryForm] = React.useState<ClientDiscoveryFormViewModel>(new ClientDiscoveryFormViewModel());
    const [loaded, setLoaded] = React.useState(false);
    const [step, setStep] = React.useState(0);
    const [isUpdated, setIsUpdated] = React.useState(false);
    const [queueSave, setQueueSave] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
    const [error, setError] = React.useState<string[]>([]);


    React.useEffect(() => {
        getData();
        const autosave = setInterval(() => {
            setQueueSave(true);
        }, 10000);
        return () => {
            clearTimeout(autosave);
            setQueueSave(false);
        }
    }, []);

    React.useEffect(() => {
        console.log(isUpdated + '|' + queueSave);
        if (isUpdated && queueSave) {
            autoSave();
        }
        setQueueSave(false);
    }, [queueSave]);

    const getData = async () => {
        setLoaded(false);
        const token = await authService.getAccessToken();

        fetch(`Form/LoadDiscovery?id=${campaignId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then((data: ReturnViewModel) => {
                setLoaded(true);
                if (!data.success) {
                    setOpenError(true);
                    setError(data.messages);
                }
                else {
                    setClientDiscoveryForm(data.value);
                }
            })
            .catch(error => {
                console.log(error);
                setLoaded(true);
            });
    }

    const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setClientDiscoveryForm({
            ...clientDiscoveryForm,
            [event.target.name]: checked
        });
        setIsUpdated(true);
    }
    const handleDropdownChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setClientDiscoveryForm({
            ...clientDiscoveryForm,
            [event.target.name]: event.target.value
        });
        setIsUpdated(true);

    }
    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setClientDiscoveryForm({
            ...clientDiscoveryForm,
            [event.target.name]: event.target.value
        });
        setIsUpdated(true);

    }

    const back = () => {
        if (step === 3 && !clientDiscoveryForm.isVelocity) {
            setStep(step - 2);
        }
        else if (step === 4 && !clientDiscoveryForm.isContent && clientDiscoveryForm.isVelocity) {
            setStep(step - 2);
        }
        else if (step === 4 && !clientDiscoveryForm.isContent && !clientDiscoveryForm.isVelocity) {
            setStep(step - 3);
        }
        if (step === 5 && !clientDiscoveryForm.isAds && clientDiscoveryForm.isContent) {
            setStep(step - 2);
        }
        else if (step === 5 && !clientDiscoveryForm.isAds && !clientDiscoveryForm.isContent && clientDiscoveryForm.isVelocity) {
            setStep(step - 3);
        }
        else if (step === 5 && !clientDiscoveryForm.isAds && !clientDiscoveryForm.isContent && !clientDiscoveryForm.isVelocity) {
            setStep(step - 4);
        }
        else {
            setStep(step - 1);
        }
    }
    const next = async () => {
        setLoaded(false);
        const token = await authService.getAccessToken();
        let data = clientDiscoveryForm;
        data.complete = step === 3;
        if (step === 1 && !clientDiscoveryForm.isVelocity && clientDiscoveryForm.isContent) {
            setStep(step + 2);
        }
        else if (step === 1 && !clientDiscoveryForm.isVelocity && !clientDiscoveryForm.isContent && clientDiscoveryForm.isAds) {
            setStep(step + 3);
        }
        else if (step === 1 && !clientDiscoveryForm.isVelocity && !clientDiscoveryForm.isContent && !clientDiscoveryForm.isAds) {
            setStep(step + 4);
        }
        else if (step === 2 && !clientDiscoveryForm.isContent && clientDiscoveryForm.isAds) {
            setStep(step + 2);
        }
        else if (step === 2 && !clientDiscoveryForm.isContent && !clientDiscoveryForm.isAds) {
            setStep(step + 3);
        }
        else if (step === 3 && !clientDiscoveryForm.isAds) {
            setStep(step + 2);
        }
        else {
            setStep(step + 1);
        }
        fetch('Form/SaveDiscovery', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then((data: ReturnViewModel) => {
                setLoaded(true);
                if (data.success) {
                    setClientDiscoveryForm(data.value);
                }
                else {
                    console.log(data.messages);
                    setOpenError(true);
                    setError(data.messages);
                }
            })
            .catch(error => {
                console.log(error);
                setLoaded(true);
            });
    }

    const autoSave = async () => {
        setSaving(true);
        const token = await authService.getAccessToken();
        let data = clientDiscoveryForm;

        fetch('Form/SaveDiscovery', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then((data: ReturnViewModel) => {
                if (!data.success) {
                    console.log(data.messages);
                    setOpenError(true);
                    setError(data.messages);
                }
                setSaving(false);
                setIsUpdated(false);
            })
            .catch(error => {
                console.log(error);

            });
    }

    const discoveryButton = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
        <RouterLink
            ref={ref}
            to={{
                pathname: '/Clients/Client',
                state: { campaignId }
            }}
            {...props} />
    ));

    const report = () => {
        fetch(`PDFGenerator/ClientDiscoveryReport?clientId=${campaignId}`)
            .then(response => response.blob())
            .then(data => {
                console.log(data)
                if (data.type !== "application/problem+json") {
                    var dateTime = new Date();
                    var formattedTime = dateTime.toLocaleDateString();
                    var url = window.URL.createObjectURL(data);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = clientDiscoveryForm.campaignName + "_ClientDiscovery_" + formattedTime + ".pdf";
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                }
                else {
                    console.log('error', 'An internal server error occured');
                }
            });
    }

    const onClose = () => {
        setOpenError(false);
    }

    return (
        <Container maxWidth="md" fixed sx={{ paddingTop: "12px" }}>
            {loaded &&
                <ValidatorForm onSubmit={next} autoComplete="off" instantValidate={true} >
                    <Grid container spacing={1} justifyContent="space-between">
                        <Grid item xs={12}>
                            <Typography variant="h5">Client Discovery - {clientDiscoveryForm.campaignName}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {step === 0 &&
                                <ClientDiscovery1 data={clientDiscoveryForm} handleCheckChange={handleCheckChange} handleDropdownChange={handleDropdownChange} handleTextChange={handleTextChange} />
                            }
                            {step === 1 &&
                                <ClientDiscovery2 data={clientDiscoveryForm} handleCheckChange={handleCheckChange} handleDropdownChange={handleDropdownChange} handleTextChange={handleTextChange} />
                            }
                            {step === 2 &&
                                <ClientDiscovery3 data={clientDiscoveryForm} handleCheckChange={handleCheckChange} handleDropdownChange={handleDropdownChange} handleTextChange={handleTextChange} />
                            }
                            {step === 3 &&
                                <ClientDiscoveryContent data={clientDiscoveryForm} handleCheckChange={handleCheckChange} handleDropdownChange={handleDropdownChange} handleTextChange={handleTextChange} />
                            }
                            {step === 4 &&
                                <ClientDiscoveryAds data={clientDiscoveryForm} handleCheckChange={handleCheckChange} handleDropdownChange={handleDropdownChange} handleTextChange={handleTextChange} />
                            }
                            {step === 5 &&
                                <ClientDiscovery4 data={clientDiscoveryForm} handleCheckChange={handleCheckChange} handleDropdownChange={handleDropdownChange} handleTextChange={handleTextChange} />
                            }
                            {step === 6 &&
                                <Typography align="center" variant="h6">Discovery Form Complete!</Typography>
                            }
                        </Grid>
                        <Grid item>
                            {(step > 0 && step < 6) && <Button variant="contained" color="primary" fullWidth onClick={back}>Back</Button>}
                            {step === 6 &&
                                <Button variant="outlined" color="primary" fullWidth onClick={report}>Download PDF</Button>
                            }
                        </Grid>
                        <Grid item>
                            {step === 6 ?
                                <Button variant="contained" color="primary" fullWidth component={discoveryButton}>Return to Client</Button>
                                :
                                <Button variant="contained" color="primary" disabled={saving} fullWidth type="submit">Save & Continue</Button>
                            }
                        </Grid>
                    </Grid>
                </ValidatorForm>
            }
            <Dialog open={openError} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth>
                <DialogTitle id="form-dialog-title">Error</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {error}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="secondary">Close</Button>
                </DialogActions>
            </Dialog>
        </Container >
    )

}